@import 'builder.scss';

.widthGrow {
    transition: width 0.5s;
}

.blockText {
    line-height: unset !important;
}

.bTitleText p {
    margin-bottom: unset !important;
}

.ql-tooltip {
    z-index: 1000000 !important;
    width: 275px !important;
}

.ql-formats {
    margin-left: unset !important;
    margin-right: unset !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.ql-color {
    margin-top: 3px;
    margin-left: 5px;
    background: whitesmoke !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
}

.ql-bold {
    width: 37px !important;
    padding-left: 15px !important;
}

.ql-italic {
    width: 37px !important;
    padding-left: 15px !important;
}

.ql-underline {
    width: 37px !important;
    padding-left: 15px !important;
}

.hideScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hideScroll::-webkit-scrollbar {
    display: none;
}

.hiddenScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.hiddenScroll::-webkit-scrollbar {
    display: none;
}

.ql-link {
    width: 37px !important;
    padding-left: 15px !important;
}

.ql-editor {
    overflow: hidden;
}

.barSection {
    display: flex;                     /* 1 */
    align-items: baseline;             /* 2 */
    margin-bottom: 10px;
}
.barSection > * {
    padding: 0;
    margin: 0;
}
.barSpan {
    flex: 1;                          /* 3 */
    overflow: hidden;                 /* 4 */
}

.tab .nav-tabs .nav-link.subactive {
    background: #262F3D;
    color: #fff;
}
